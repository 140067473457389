import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { NbAuthJWTToken, NbAuthModule, NbPasswordAuthStrategy } from '@nebular/auth';
import { AuthGuard } from './auth/auth-guard.service';
import { AuthInterceptor } from './auth/auth.interceptor';
import { environment } from '../environments/environment.prod';


// const formSetting: any = {
//   redirectDelay: 0,
//   showMessages: {
//     success: true,
// //   },
// };



export const defaultSettings: any = {
  forms: {
    login: {
      redirectDelay: 500, // delay before redirect after a successful login, while success message is shown to the user
      strategy: 'password',  // strategy id key.
      rememberMe: true,   // whether to show or not the `rememberMe` checkbox
      showMessages: {     // show/not show success/error messages
        success: true,
        error: true,
      },


      // socialLinks: socialLinks, // social links at the bottom of a page
    },
    register: {
      redirectDelay: 500,
      strategy: 'password',
      showMessages: {
        success: true,
        error: true,
      },
      terms: true,
      // socialLinks: socialLinks,
    },
    // requestPassword: {
    //   redirectDelay: 500,
    //   strategy: 'email',
    //   showMessages: {
    //     success: true,
    //     error: true,
    //   },
    //   // socialLinks: socialLinks,
    // },
    // resetPassword: {
    //   redirectDelay: 500,
    //   strategy: 'email',
    //   showMessages: {
    //     success: true,
    //     error: true,
    //   },
    //   // socialLinks: socialLinks,
    // },
    logout: {
      redirectDelay: 500,
      strategy: 'password',
    },
    validation: {
      password: {
        required: true,
        minLength: 4,
        maxLength: 50,
      },
      email: {
        required: true,
      },
      fullName: {
        required: true,
        minLength: 4,
        maxLength: 50,
      },
    },
  },
};

@NgModule({
  declarations: [AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),

    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email',
          baseEndpoint: environment.baseEndpoint,
          login: {
            endpoint: '/sign-in-local',
            method: 'post',

            requireValidToken: true,
            redirect: {
              success: '/dashboard/',
              failure: null, // stay on the same page
            },
          },
          register: {
            endpoint: '/sign-up-local',
            method: 'post',
            requireValidToken: true,
            redirect: {
              success: '/dashboard/',
              failure: null, // stay on the same page
            },
          },
          logout: {
            endpoint: '/logout',
            method: 'post',
          },
          // requestPass: {
          //   endpoint: '/auth/request-pass',
          //   method: 'post',
          // },
          // resetPass: {
          //   endpoint: '/auth/reset-pass',
          //   method: 'post',
          // },
          token: {
            class: NbAuthJWTToken,
            key: 'token'
          }
        }),
      ],
      forms: defaultSettings
      // forms: {
      //   login: formSetting,
      //   register: formSetting,
      //   requestPassword: formSetting,
      //   resetPassword: formSetting,
      //   logout: {
      //     redirectDelay: 100,
      //   },
      // },
    }),
  ],
  bootstrap: [AppComponent],
  providers: [AuthGuard, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }]

})


export class AppModule {

}

