import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
 <b> VideoMetadoc-AWS </b >
  `,
})
export class FooterComponent {
  // <img style="width: 40px; height: 40px;" src = "./assets/logo/apple-icon-60x60.png" />  
  //    <span class="created-by" >
  // Created with ♥ by < b > <a href="https://akveo.page.link/8V2f" target = "_blank" > Akveo <b /a></b > 2019
  //   < /span>
  //   < div class="socials" >
  //     <a href="#" target = "_blank" class="ion ion-social-github" > </a>
  //       < a href = "#" target = "_blank" class="ion ion-social-facebook" > </>
  //         < a href = "#" target = "_blank" class="ion ion-social-twitter" > </>
  //           < a href = "#" target = "_blank" class="ion ion-social-linkedin" > </>
  //             < /div>
}
