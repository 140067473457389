import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProgressInfo, StatsProgressBarData } from '../type/stats-progress-bar';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable()
export class StatsProgressBarService extends StatsProgressBarData {


  constructor(private http: HttpClient) {
    super();
  }
  private progressInfoData: ProgressInfo[] = [
    {
      title: 'Total Profit',
      value: 572900,
      activeProgress: 0,
      description: '',
    },
    {
      title: 'This week Profit',
      value: 572900,
      activeProgress: 70.5,
      description: 'Better than last week (70%)',
    },
    {
      title: 'This month Profit',
      value: 200,
      activeProgress: 55,
      description: 'Better than last month (55%)',
    },
    {
      title: 'Total Orders',
      value: 6378,
      activeProgress: 0,
      description: '',
    },


    // {
    //   title: 'New Comments',
    //   value: 200,
    //   activeProgress: 55,
    //   description: 'Better than last week (55%)',
    // },
  ];
  getProgressInfoData(): Observable<ProgressInfo[]> {

    return this.http.get<ProgressInfo[]>(environment.apiOrdersProfit)

  }
  // getProgressInfoData(): Observable<ProgressInfo[]> {
  //   return observableOf(this.progressInfoData);
  // }
}
