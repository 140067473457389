import { Observable } from "rxjs";
import { ProductInterface } from "./product.type";

export abstract class ProductsSmartTableData {
  // abstract getData(): any[];
  abstract getProducts(): Observable<any>;
  abstract postProduct(product: any): Observable<any>
  abstract putProduct(product: any): Observable<any>
  abstract deleteProduct(id: number): Observable<any>
  abstract postImage(image: any): Observable<any>
}
