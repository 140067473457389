import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment.prod';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AdminUsersSmartTableData } from '../type/admin-users-smart-table';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminUsersTableService extends AdminUsersSmartTableData {

  constructor(private http: HttpClient) {
    super();
  }
  private cachedUserAdminData: { [cacheKey: string]: Observable<any> } = {};



  postUser(user: any): Observable<any> {
    console.log(user);
    return this.http.post<any>(environment.apiAdminUsers, user)
  }


  putUser(user: any): Observable<any> {
    console.log(user);
    return this.http.put<any>(environment.apiAdminUsers, user)
  }
  disableUser(adminId: number): Observable<any> {
    const params = new HttpParams().set('adminId', adminId.toString());
    return this.http.delete(environment.apiAdminUsers, { params });
  }

}
export type AdminUser = {
  adminId: number
  username: string,
  firstName: string
  lastName: string,
  email: string
  password: string
  active: number,
  photoUrl: string,
  phone: string,
  role: string,
  createdAt: string;
  updatedAt: string
}
