import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class AnalyticsService {
  private enabled: boolean;
  constructor(private location: Location, private router: Router, private http: HttpClient) {
    this.enabled = false;
 
 
  }
  
  getGoogleAnalysticMetrics():Observable<any>
  {
   return  this.http.get<any>('http://localhost:3000/api/v1/admin/google-analystic')

  }


  // trackPageViews() {
  //   if (this.enabled) {
  //     this.router.events.pipe(
  //       filter((event) => event instanceof NavigationEnd),
  //     )

  //       .subscribe(() => {
  //         ga('send', { hitType: 'pageview', page: this.location.path() });
  //       });
  //   }
  // }

  // trackEvent(eventName: string) {
  //   if (this.enabled) {

  //     // console.log(eventName);

  //     ga('send', 'event', eventName);
  //   }
  // }
}
