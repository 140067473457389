import { Observable } from 'rxjs';
export interface ChartData {
  chartLabel: string[];
  data: number[][];
}


export interface OrderProfitChartSummary {
  title: string;
  value: number;
}

export abstract class OrdersProfitChartData {
  abstract getOrderProfitChartSummary(): Observable<OrderProfitChartSummary[]>;
  abstract getChartData(period: string): Observable<ChartData>;
  // abstract getOrdersChartData(period: string): Observable<OrdersChart>;
  // abstract getProfitChartData(period: string): Observable<ProfitChart>;
}
