import { Observable } from "rxjs";

export abstract class UsersSmartTableData {


    abstract putUser(user: any): Observable<string>
    abstract postUser(user: any): Observable<string>
    abstract disableUser(userId: number): Observable<string>

}
