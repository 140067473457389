import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment.prod';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OrdersSmartTableData } from '../type/orders-smart-table';
import { OrderType } from '../type/order.type';

@Injectable({
    providedIn: 'root'
})
export class OrdersTableService extends OrdersSmartTableData {
    articles = new BehaviorSubject<any>({ items: [] });

    constructor(private http: HttpClient) {
        super();
    }

    getOrders(): Observable<OrderType[]> {

        return this.http.get<OrderType[]>(environment.apiOrders)
    }

    postOrder(order: any): Observable<string> {

        return this.http.post<any>(environment.apiOrders, order)
    }


    putOrder(order: any): Observable<string> {

        return this.http.put<any>(environment.apiOrders, order)
    }
    disableOrder(id: number): Observable<string> {

        const params = new HttpParams().set('id', id.toString());


        return this.http.delete<string>(environment.apiOrders, { params })
        // null is anoter parameter ...
    }

    getDialogInputData(data: string): Observable<any> {

        return this.http.get<any>(environment.apiOrdersInputData + data)


    }


    getOrderProducts(id: string): Observable<any> {

        return this.http.get<any>(environment.apiOrderProducts + id)

    }






    getProductById(id: string): Observable<any> {

        return this.http.get<any>(environment.apiProduct + id);

    }


  


}
