import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomPromtService {

constructor() { }
  showPrompt(): void {
    const storedCode = localStorage.getItem('validCode');
    if (storedCode === 'true') {
      return; // Dacă codul a fost deja introdus corect, nu se mai afișează promptul
    }

    let validCode = false;
    while (!validCode) {
      const code = window.prompt('Enter the code:');

      if (code === null) {
        const code = window.prompt('Enter the code:');
      }

      validCode = this.validateCode(code);
      if (!validCode) {
        window.alert('The code entered is incorrect. Please try again.');
      } else {
        // Setăm stocarea locală pentru a reține că codul a fost introdus corect
        localStorage.setItem('validCode', 'true');
      }
    }
  }

  validateCode(code: string): boolean {
    return code === '002024';
  }

}
