import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(private http: HttpClient) { }

  getProductTranslations(productId: number): Observable<any> {
    return this.http.get(environment.apiGetTranslationsProductsById + productId);
  }

  updateProductTranslations(keyId: number, translations: any): Observable<any> {
    return this.http.post(environment.apiPostTranslationsProductsById, { keyId, translations });
  }



  getFeaturesTranslations(productId: number): Observable<any> {
    return this.http.get(environment.apiGetTranslationsProductsById + productId);
  }

  updateFeaturesTranslations(keyId: number, translations: any): Observable<any> {
    return this.http.post(environment.apiPostTranslationsProductsById, { keyId, translations });
  }


}
