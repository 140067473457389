import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment.prod';
import { HttpClient, HttpParams } from '@angular/common/http';
@Injectable({
    providedIn: 'root'
})
export class UsersTableService  {
    constructor(private http: HttpClient) {
        
    }

 

    postUser(user: any): Observable<any> {

        return this.http.post<any>(environment.apiWebsiteUsers, user)
    }


    putUser(user: any): Observable<any> {

        return this.http.put<any>(environment.apiWebsiteUsers, user)
    }
    disableUser(userId: number): Observable<any> {

        const params = new HttpParams().set('userId', userId.toString());


        return this.http.delete<string>(environment.apiWebsiteUsers, { params })
        // null is anoter parameter ...
    }



}

