import { Observable } from "rxjs";
import { OrderType } from "./order.type";

export abstract class OrdersSmartTableData {
    // // abstract getData(): any[];
    // abstract getProducts(): Observable<Product[]>;
    // abstract postProduct(product: Product): Observable<string>
    // abstract putProduct(product: Product): Observable<string>
    // abstract deleteProduct(id: number): Observable<string>
    // abstract postImage(image: any): Observable<string>

    abstract getOrders(): Observable<OrderType[]>
    abstract putOrder(order: any): Observable<string>
    abstract postOrder(order: any): Observable<string>
    abstract disableOrder(id: number): Observable<string>
    abstract getDialogInputData(data: string): Observable<any>
}
