import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { OrdersChart, OrdersChartData } from '../type/orders-chart';
import { OrderProfitChartSummary, OrdersProfitChartData } from '../type/orders-profit-chart';
import { ProfitChart, ProfitChartData } from '../type/profit-chart';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
@Injectable()
export class OrdersProfitChartService extends OrdersProfitChartData {

  // private summary = [
  //   {
  //     title: 'Marketplace',
  //     value: 3654,
  //   },
  //   {
  //     title: 'Last Month',
  //     value: 946,
  //   },
  //   {
  //     title: 'Last Week',
  //     value: 654,
  //   },
  //   {
  //     title: 'Today',
  //     value: 230,
  //   },
  // ];

  constructor(private ordersChartService: OrdersChartData,
    private profitChartService: ProfitChartData, private Http: HttpClient) {
    super();
  }

  getOrderProfitChartSummary(): Observable<OrderProfitChartSummary[]> {
    return this.Http.get<OrderProfitChartSummary[]>(environment.apiOrdersProfitChartSummary);
  }

  // getOrderProfitChartSummary(): Observable<OrderProfitChartSummary[]> {
  //   return observableOf(this.summary);
  // }

  // getOrdersData():Observable<any>
  // {
  //   return this.Http.get<any>(environment.apiOrdersData);

  // }

  getChartData(period: string): Observable<any> {
    return this.Http.get<any>(environment.apiChartData + period);

  }

  getOrdersChartData(period: string): Observable<OrdersChart> {
    return observableOf(this.ordersChartService.getOrdersChartData(period));
  }

  getProfitChartData(period: string): Observable<ProfitChart> {
    return observableOf(this.profitChartService.getProfitChartData(period));
  }
}
