import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment.prod';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProductInterface } from '../type/product.type';

@Injectable({
  providedIn: 'root'
})
export class ProductsTableService {
  constructor(private http: HttpClient) {
  }


  putProduct(product: ProductInterface): Observable<any> {

    return this.http.put<any>(environment.apiProducts, product)

  }

  deleteProduct(id: number): Observable<any> {

    const params = new HttpParams().set('id', id.toString());


    return this.http.delete<any>(environment.apiProducts, { params })
    // null is anoter parameter ...
  }

  postProduct(product: any): Observable<any> {
    return this.http.post<any>(environment.apiProducts, product);

  }




  putFeature(feature: any): Observable<any> {

    return this.http.put<any>(environment.apiFeatures, feature)

  }

  deleteFeature(product: any): Observable<any> {

    const params = new HttpParams().set('productId', product.productId.toString()).set('featureId', product.featureId.toString());


    return this.http.delete<any>(environment.apiFeatures, { params })
    // null is anoter parameter ...
  }

  postFeature(feature: any): Observable<any> {
    return this.http.post<any>(environment.apiFeatures, feature);

  }


  getProductImages(productId: number): Observable<any> {
    const params = new HttpParams().set('productId', productId.toString());
    return this.http.get(environment.apiProductImages, { params });
  }


  uploadProductImage(productId: number, formData: FormData): Observable<any> {
    return this.http.post(`/api/products/${productId}/upload`, formData);
  }

  addProductImage(imageData: any): Observable<any> {
    return this.http.post(environment.apiProductImages, imageData);
  }
  updateProductImage(imageData: any): Observable<any> {
    return this.http.put(environment.apiProductImages, imageData);
  }

  deleteProductImage(imageData: any): Observable<any> {

    const params = new HttpParams().set('productId', imageData.productId.toString()).set('imageId', imageData.imageId.toString());

    return this.http.delete(environment.apiProductImages, { params });
  }


  postImage(Image: any): Observable<any> {

    return this.http.post<any>(environment.apiImageUpload, Image)

  }

  getTableData(): Observable<any> {
    return this.http.get<any>(environment.apiGetTableData);
  }










  putCrossSellProduct(crossSellProduct: any): Observable<any> {

    return this.http.put<any>(environment.apiCrossSellProducts, crossSellProduct)

  }

  deleteCrossSellProduct(crossSellId: any): Observable<any> {

    const params = new HttpParams().set('crossSellId', crossSellId.toString())


    return this.http.delete<any>(environment.apiCrossSellProducts, { params })
    // null is anoter parameter ...
  }

  postCrossSellProduct(crossSellProduct: any): Observable<any> {
    return this.http.post<any>(environment.apiCrossSellProducts, crossSellProduct);

  }



}
