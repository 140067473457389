


export const environment = {
  production: true,



  apiGetTranslationsProductsById: 'https://back.videometadoc.md/api/v1/admin/products-translations/',
  apiPostTranslationsProductsById: 'https://back.videometadoc.md/api/v1/admin/products/translations',
  apiGetFeaturesTranslationsById: 'https://back.videometadoc.md/api/v1/admin/features-translations/',
  apiPostFeaturesTranslationsById: 'https://back.videometadoc.md/api/v1/admin/features/translations',
  apiGetTableData: 'https://back.videometadoc.md/api/v1/admin/table-data',
  apiProductImages: 'https://back.videometadoc.md/api/v1/admin/product-images',
  apiGetFeatures: 'https://back.videometadoc.md/api/v1/admin/features/',
  apiFeatures: 'https://back.videometadoc.md/api/v1/admin/features',
  apiProducts: 'https://back.videometadoc.md/api/v1/admin/products',
  apiImageUpload: 'https://back.videometadoc.md/api/v1/admin/image-upload',
  apiOrdersProfitChartSummary: 'https://back.videometadoc.md/api/v1/admin/summary-orders',
  apiChartData: 'https://back.videometadoc.md/api/v1/admin/orders-data/',
  apiOrdersProfit: 'https://back.videometadoc.md/api/v1/admin/orders-profit',
  apiOrders: 'https://back.videometadoc.md/api/v1/admin/orders',
  apiOrdersInputData: 'https://back.videometadoc.md/api/v1/admin/orders-input-data/',
  apiOrderProducts: 'https://back.videometadoc.md/api/v1/admin/order-products/',
  apiProduct: 'https://back.videometadoc.md/api/v1/admin/product/',
  apiWebsiteUsers: 'https://back.videometadoc.md/api/v1/admin/website-users',
  apiAdminUsers: 'https://back.videometadoc.md/api/v1/admin/admin-users',
  apiCoupons: 'https://back.videometadoc.md/api/v1/admin/coupons',
  apiBackgroundImages: 'https://back.videometadoc.md/api/v1/admin/background-images',

  apiSubscriptions: 'https://back.videometadoc.md/api/v1/admin/subscriptions',
  apiVideoTutorials: 'https://back.videometadoc.md/api/v1/admin/video-tutorials',
  apiReviews: 'https://back.videometadoc.md/api/v1/admin/reviews',
apiCampaignsProduct: 'https://back.videometadoc.md/api/v1/admin/campaigns-product',

  baseEndpoint: 'https://back.videometadoc.md/api/v1/admin',
  apiCrossSellProducts: 'https://back.videometadoc.md/api/v1/admin/cross-sell-products',
  apiDiscountCampaigns: 'https://back.videometadoc.md/api/v1/admin/discount-campaigns',
  TOKEN_ROUTE: '0024'
};


// export const environment = {
//   production: false,
//   apiGetTranslationsProductsById: 'http://localhost:3000/api/v1/admin/products-translations/',
//   apiPostTranslationsProductsById: 'http://localhost:3000/api/v1/admin/products/translations',
//   apiGetFeaturesTranslationsById: 'http://localhost:3000/api/v1/admin/features-translations/',
//   apiPostFeaturesTranslationsById: 'http://localhost:3000/api/v1/admin/features/translations',
//   apiGetTableData: 'http://localhost:3000/api/v1/admin/table-data',
//   apiProductImages: 'http://localhost:3000/api/v1/admin/product-images',
//   apiGetFeatures: 'http://localhost:3000/api/v1/admin/features/',
//   apiFeatures: 'http://localhost:3000/api/v1/admin/features',
//   apiProducts: 'http://localhost:3000/api/v1/admin/products',
//   apiImageUpload: 'http://localhost:3000/api/v1/admin/image-upload',
//   apiOrdersProfitChartSummary: 'http://localhost:3000/api/v1/admin/summary-orders',
//   apiChartData: 'http://localhost:3000/api/v1/admin/orders-data/',
//   apiOrdersProfit: 'http://localhost:3000/api/v1/admin/orders-profit',
//   apiOrders: 'http://localhost:3000/api/v1/admin/orders',
//   apiOrdersInputData: 'http://localhost:3000/api/v1/admin/orders-input-data/',
//   apiOrderProducts: 'http://localhost:3000/api/v1/admin/order-products/',
//   apiProduct: 'http://localhost:3000/api/v1/admin/product/',
//   apiWebsiteUsers: 'http://localhost:3000/api/v1/admin/website-users',
//   apiAdminUsers: 'http://localhost:3000/api/v1/admin/admin-users',
//   apiCoupons: 'http://localhost:3000/api/v1/admin/coupons',
//   apiBackgroundImages: 'http://localhost:3000/api/v1/admin/background-images',

//   apiSubscriptions: 'http://localhost:3000/api/v1/admin/subscriptions',
//   apiVideoTutorials: 'http://localhost:3000/api/v1/admin/video-tutorials',
//   apiReviews: 'http://localhost:3000/api/v1/admin/reviews',
//   apiCrossSellProducts: 'http://localhost:3000/api/v1/admin/cross-sell-products',
//   apiDiscountCampaigns: 'http://localhost:3000/api/v1/admin/discount-campaigns',
//   apiCampaignsProduct : 'http://localhost:3000/api/v1/admin/campaigns-product',
//   baseEndpoint: 'http://localhost:3000/api/v1/admin',
//   TOKEN_ROUTE: '0'

// };
