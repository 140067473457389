import { Observable } from "rxjs";

export abstract class AdminUsersSmartTableData {


    abstract putUser(user: any): Observable<any>
    abstract postUser(user: any): Observable<any>
    abstract disableUser(adminId: any): Observable<any>


}
