import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from "rxjs";
import { NbAuthService } from "@nebular/auth";
import { environment } from "../../environments/environment.prod";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {


  constructor(private router: Router, private authService: NbAuthService,) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {



    return this.authService.getToken().pipe(
      // switchMap operator is employed to switch the context of the Observable, essentially transitioning from one Observable to another
      switchMap((data: any) => {
        const authToken = data.token;
        if (authToken) {
          request = request.clone({
            setHeaders: {
              'X-Internal-Authorization': `Bearer ${authToken}`,
              'X-Internal-AuthorizationRoute': `Bearer ${environment.TOKEN_ROUTE}`
            }
          });
        }

        else {
          this.router.navigate(['/auth/login'])
          // this for protect all back-end routes without token !
          request = request.clone({
            setHeaders: {
              'X-Internal-AuthorizationRoute': `Bearer ${environment.TOKEN_ROUTE}`
            }
          });


        }





        return next.handle(request);
      })
    );



  }








}