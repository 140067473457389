import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbDummyAuthStrategy } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  PlayerService,
  SeoService,
  StateService,
} from './utils';
import { ElectricityData } from './type/electricity';
import { ProductsSmartTableData } from './type/products-smart-table';
import { UserActivityData } from './type/user-activity';
import { OrdersChartData } from './type/orders-chart';
import { ProfitChartData } from './type/profit-chart';
import { TrafficListData } from './type/traffic-list';
import { EarningData } from './type/earning';
import { OrdersProfitChartData } from './type/orders-profit-chart';
import { TrafficBarData } from './type/traffic-bar';
import { ProfitBarAnimationChartData } from './type/profit-bar-animation-chart';
import { TemperatureHumidityData } from './type/temperature-humidity';
import { SolarData } from './type/solar';
import { TrafficChartData } from './type/traffic-chart';
import { StatsBarData } from './type/stats-bar';
import { CountryOrderData } from './type/country-order';
import { StatsProgressBarData } from './type/stats-progress-bar';
import { VisitorsAnalyticsData } from './type/visitors-analytics';
import { SecurityCamerasData } from './type/security-cameras';
import { ElectricityService } from './services/electricity.service';
import { ProductsTableService } from './services/products-table.service';
import { UserActivityService } from './services/user-activity.service';
import { OrdersChartService } from './services/orders-chart.service';
import { ProfitChartService } from './services/profit-chart.service';
import { TrafficListService } from './services/traffic-list.service';
import { EarningService } from './services/earning.service';
import { OrdersProfitChartService } from './services/orders-profit-chart.service';
import { TrafficBarService } from './services/traffic-bar.service';
import { ProfitBarAnimationChartService } from './services/profit-bar-animation-chart.service';
import { TemperatureHumidityService } from './services/temperature-humidity.service';
import { SolarService } from './services/solar.service';
import { TrafficChartService } from './services/traffic-chart.service';
import { StatsBarService } from './services/stats-bar.service';
import { CountryOrderService } from './services/country-order.service';
import { StatsProgressBarService } from './services/stats-progress-bar.service';
import { VisitorsAnalyticsService } from './services/visitors-analytics.service';
import { SecurityCamerasService } from './services/security-cameras.service';
import { MockDataModule } from './services/mock-data.module';
import { OrdersSmartTableData } from './type/orders-smart-table';
import { OrdersTableService } from './services/orders-table.service';
import { UsersSmartTableData } from './type/users-smart-table';
import { UsersTableService } from './services/users-table.service';
import { RoleProvider } from '../security/role-provider';
import { UserService } from './services/users.service';
import { UserData } from './type/users';
import { AdminUsersTableService } from './services/admin-users-table.service';
import { AdminUsersSmartTableData } from './type/admin-users-smart-table';
import { TranslationService } from './services/translation.service';

// const socialLinks = [
//   {
//     url: 'https://github.com/akveo/nebular',
//     target: '_blank',
//     icon: 'github',
//   },
//   {
//     url: 'https://www.facebook.com/akveo/',
//     target: '_blank',
//     icon: 'facebook',
//   },
//   {
//     url: 'https://twitter.com/akveo_inc',
//     target: '_blank',
//     icon: 'twitter',
//   },
// ];

const DATA_SERVICES = [
  { provide: AdminUsersSmartTableData, useClass: AdminUsersTableService },
  { provide: UsersSmartTableData, useClass: UsersTableService },
  { provide: ElectricityData, useClass: ElectricityService },
  { provide: ProductsSmartTableData, useClass: ProductsTableService },
  { provide: OrdersSmartTableData, useClass: OrdersTableService },
  { provide: UserActivityData, useClass: UserActivityService },
  { provide: OrdersChartData, useClass: OrdersChartService },
  { provide: ProfitChartData, useClass: ProfitChartService },
  { provide: TrafficListData, useClass: TrafficListService },
  { provide: EarningData, useClass: EarningService },
  { provide: OrdersProfitChartData, useClass: OrdersProfitChartService },
  { provide: TrafficBarData, useClass: TrafficBarService },
  { provide: ProfitBarAnimationChartData, useClass: ProfitBarAnimationChartService },
  { provide: TemperatureHumidityData, useClass: TemperatureHumidityService },
  { provide: SolarData, useClass: SolarService },
  { provide: TrafficChartData, useClass: TrafficChartService },
  { provide: StatsBarData, useClass: StatsBarService },
  { provide: CountryOrderData, useClass: CountryOrderService },
  { provide: StatsProgressBarData, useClass: StatsProgressBarService },
  { provide: VisitorsAnalyticsData, useClass: VisitorsAnalyticsService },
  { provide: SecurityCamerasData, useClass: SecurityCamerasService },
];

const newDataServices = [
  TranslationService

]


export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...newDataServices,
  ...NbAuthModule.forRoot({

    strategies: [
      NbDummyAuthStrategy.setup({
        name: 'email',
        delay: 3000,
      }),
    ],
    // forms: {
    //   login: {
    //     socialLinks: socialLinks,
    //   },
    //   register: {
    //     socialLinks: socialLinks,
    //   },
    // },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      // guest: {
      //   view: '*',
      // },
      user: {
        view: '*',
        create: 'orders',
        edit: 'orders',

      },
      moderator: {
        parent: 'user',
        create: '*',
        remove: '*',
        edit: '*'
      },
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: RoleProvider,
  },
  AnalyticsService,
  LayoutService,
  PlayerService,
  SeoService,
  StateService,
];




@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})






export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
